import React from 'react';
import clsx from 'clsx';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import { meta } from 'metaData';
import { sectionRender, talkEmailLink, homeLink } from '../sectionRender';
import styles from '../cookies.module.css';

const firstSection = [
  {
    type: 'paragraphs',
    content: [
      'Last updated: (15.07.2020)',
      'These Terms of Use (“Terms”) are a legal contract between you and/or the entity or company that you represent (“you” or “your”) and Brocoders Services OÜ (“we”, “us” or “Brocoders”), and govern your use of all the text, data, information, software, graphics, and other content (collectively “Content”) that we and our affiliates may make available to you.',
      'Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before browsing the http://brocoders.com website (the "Website") operated by Brocoders Services OÜ ("us", "we", “Brocoders”  or "our").',
      'Your access to and use of the Website is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Website.',
      'By accessing or using the Website you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Website.',
    ],
  },
];

const websiteOwner = [
  { type: 'subtitle', content: 'Website owner and administrator' },
  {
    type: 'paragraphs',
    content: [
      'The brocoders.com website’s (“Website”) owner and administrator is Brocoders Services OÜ',
      'We may modify, suspend or discontinue this website and/or the Content at any time and without notifying you. We reserve the right, at our sole discretion, to modify or replace these Terms at any time.',
    ],
  },
];

const copyright = [
  { type: 'subtitle', content: 'Copyright' },
  {
    type: 'paragraphs',
    content: [
      `Use of any part of or content from this website for commercial purposes of any kind is expressly prohibited. To request permission to use any of the content on this website for commercial purposes, please contact our company at ${talkEmailLink}.`,
      'Under no circumstances may any of the content on this website be displayed on either a commercial or personal website without obtaining prior permission. Unauthorized use of any information from this website may violate copyright, trademark and other laws.',
    ],
  },
];

const disclaimer = [
  { type: 'subtitle', content: 'Disclaimer' },
  {
    type: 'paragraphs',
    content: [
      'This website and all information contained herein is provided “as is”, without warranty of any kind, either express or implied, including but not limited to any implied warranty of merchantability or fitness for a particular purpose.',
      'The content, texts, documents, and related illustrations published on this website could include technical inaccuracies or typographical mistakes. Brocoders Services OÜ may make changes and/or enhancements in the content of this website at any time, without notification.',
      'Without limiting the generality of the foregoing, we make no warranty that your use of our websites will be uninterrupted, timely, secure, or error free or that defects will be corrected. No advice or information, whether oral or written, obtained by you through this website or from us or our subsidiaries/other affiliated companies shall create any warranty. We disclaim all equitable indemnities.',
    ],
  },
];

const limitationOfLiability = [
  { type: 'subtitle', content: 'Limitation of Liability' },
  {
    type: 'paragraphs',
    content: [
      'In no event shall Brocoders Services OÜ be liable for any direct, indirect, special, or consequential damages, or any other damages whatsoever, for any use of or reliance on this website, or any linked or referenced website, including, without limitation, any lost opportunity, lost profits, business interruption, loss of programs or data, even if expressly advised or otherwise aware of the possibility of such damages, whether in an action of contract, negligence, tort, or otherwise.',
    ],
  },
];

const applicableLaw = [
  { type: 'subtitle', content: 'Applicable law' },
  {
    type: 'paragraphs',
    content: [
      'By using this Website, you agree that the laws of Estonia, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and Brocoders.',
    ],
  },
];

const localLaws = [
  { type: 'subtitle', content: 'Local laws' },
  {
    type: 'paragraphs',
    content: [
      'We control and operate the website from our headquarter in Estonia, and the entirety of the website may not be appropriate or available for use in other locations. If you use our websites outside Estonia, you are solely responsible for following applicable local laws.',
    ],
  },
];

const contactUs = [
  { type: 'subtitle', content: 'Contact us' },
  {
    type: 'paragraphs',
    content: [
      `If you have any questions about these Terms or otherwise need to contact us for any reason, you can reach us at ${talkEmailLink}.`,
    ],
  },
];

const linksToOtherWebSites = [
  { type: 'subtitle', content: 'Links To Other Web Sites' },
  {
    type: 'paragraphs',
    content: [
      'Our Service may contain links to third-party web sites or services that are not owned or controlled by Brocoders Services OÜ.',
      'Brocoders Services OÜ has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Brocoders Services OÜ shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.',
    ],
  },
];

const pageSections = [
  firstSection,
  websiteOwner,
  copyright,
  disclaimer,
  limitationOfLiability,
  applicableLaw,
  localLaws,
  contactUs,
  linksToOtherWebSites,
];

function TermsOfUse() {
  return (
    <Layout isRed metaData={{ main: meta.cookies }}>
      <main className={clsx('inner-container', styles.main)}>
        <h1 className={styles.title}>Terms of Use</h1>
        {pageSections.map((item) => sectionRender(item))}
      </main>
      <Footer />
    </Layout>
  );
}

export default TermsOfUse;
